<template>
  <div id="apps">
     <div class="darxinxi clear">
      <img src="../assets/timg.jpeg" class="bj"/>
      <div class="tabox clear">
        <h2>个人信息</h2>
        <ul>
          <li><span>团长姓名</span><span class="sp">{{info.name}}</span></li>
          <li><span>团长手机</span><span class="sp">{{info.phone}}</span></li>
          <li><span>加入时间</span><span class="sp">{{info.createTime}}</span></li>
        </ul>
        <img v-if="info.qr" :src="info.qr.qrUrl" class=""erwm/>
        <p>（长按分享二维码）</p>
      </div>
     </div>
  </div>
</template>
<script>
import {getDrInfo} from "@/api/user";

export default {
  name: 'app',
  components:{
  },
   data () {
      return {
        info: {}
      }
  },
  methods:{
    loadInfo() {
      getDrInfo().then(data => {
        if (data.success = true) {
          this.info = data.data
        }
      })
    }
  },
  created() {
    this.loadInfo()
  }
}
</script>
<style>
  .clear{clear:both;overflow: hidden;}
  *{margin:0;padding:0;}
  body{background:#F8F8F8;height: 100vh;}
  .darxinxi{width:100%;position: relative;height: 100vh;}
  .darxinxi .bj{width:100%;height:22vh;display: block;}
  .darxinxi .tabox{position: absolute;width:90%;margin:0;left:5%;right:0;top:10vh;background:#fff;border-radius:10px;}
  .darxinxi .tabox img{width:30%;display: block;margin:3% auto;}
  .line{padding:15% 0;width:88%;margin:3% auto 8% auto;border-top:dashed 1px #DDDDDD;border-bottom:dashed 1px #DDDDDD;}
  .darxinxi .tabox p{display: block;font-size:12px;margin:6% auto 13% auto;text-align: center;}
  .darxinxi .tabox ul li{color:#505050;width:90%;margin:3% auto 0 auto;clear:both;overflow: hidden;}
  .darxinxi .tabox ul li span{display: inline-block;width:50%;float: left;font-size:13px; }
  .darxinxi .tabox ul li .sp{float:right;text-align: right;}
   .darxinxi .tabox h2{font-weight:400;font-size:14px;margin:10% auto 6% auto;text-align: center;}
</style>
